import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import ProductsSimple from '../products/ProductsSimple'
import { useNavigate } from 'react-router-dom'
import { Styles } from '../../base/Styles'
import { useTranslation } from 'react-i18next'

export default function Home() {
  const [user] = useAuthState(auth)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  if (user && !user.emailVerified) {
    navigate('/emailVerification')
  }

  if (user) {
    return <ProductsSimple />
  }

  return (
    <>
      <div className='flex flex-col items-center justify-center h-screen'>
        <div>
          <h1 className='text-4xl font-bold text-center text-rb-purple'>
            Welcome to Royal Bakes & Spices!
          </h1>
        </div>
        <div className='mt-4'>
          <h2 className='text-2xl font-bold text-center text-rb-orange'>
            Sign in to see products and place orders.
          </h2>
        </div>
        <div className='mt-4 flex'>
          {!user && (
            <a
              href='/'
              // className='hidden sm:block'
              onClick={(e) => {
                e.preventDefault()
                navigate('/signin')
              }}
            >
              <span className={Styles.button}>{t('signIn')}</span>
            </a>
          )}

          {!user && (
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                navigate('/signup')
              }}
            >
              <span className={Styles.buttonOutline}>{t('signUp')}</span>
            </a>
          )}
        </div>
      </div>
    </>
  )
}
