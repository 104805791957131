
// users/:id/orders 
export class Order {
  orderDate!: string | Date | object
  orderId!: string | null
  userId!: string | null
  invoiceId!: string | null
  invoiceDate!: string | Date | object
  cancelledDate!: string | Date | object
  cancelReason!: string

  subTotal!: number
  taxes!: any[]
  taxAmount!: number
  deliveryCharge!: number
  deliveryChargeTax!: number | undefined
  deliveryChargeTaxAmount!: number
  totalAmount!: number

  balanceAmount!: number
  paidAmount!: number
  paidMethod!: 'cash' | 'paypay' | ''
  deliveryTimeslot!: string | undefined
  deliveryDate!: string | Date | object
  deliveryAddress!: string
  deliveryName!: string | undefined
  deliveryPhone!: string | undefined
  pickupTimeslot!: string | undefined
  comments!: string
  orderStatus!: 'pending' | 'confirmed' | 'cancelled' | 'completed'
}

export class ProductOrder {
  id: string | null = null
  key!: string | null
  additionDate!: string | Date
  userId!: string | null | undefined
  orderId!: string | null | undefined
  productId!: string
  pricingId!: string
  nameEn!: string
  nameJp!: string
  unit!: string
  price!: number
  quantity!: number
  comments!: string
  thumbImage!: string
  taxRate!: number | undefined
  // taxAmount!: number
}