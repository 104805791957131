import { useTranslation } from 'react-i18next'
import { IoCartOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { Page } from '../base/Page'
import { Styles } from '../base/Styles'
import { CartContent } from './ordering/CartContent'
import { useCartStore } from '../base/CartStore'

export default function CartView() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { items } = useCartStore()

  if (!items || items.length === 0) {
    return (
      <>
        <Page>
          <div className='flex flex-col items-center justify-center bg-rb-background md:w-1/4 sm:w-3/4 rounded-lg shadow-md p-6'>
            <IoCartOutline size='10em' />
            {t('emptyCart')}
          </div>
        </Page>
      </>
    )
  }

  return (
    <>
      <Page>
        <div className='flex flex-col bg-rb-background w-auto rounded-lg shadow-md p-3'>
          <div className='flex w-full items-center justify-between py-3'>
            <IoCartOutline size='2em' />
            <button
              className={Styles.button}
              onClick={() => {
                navigate('/checkout')
              }}
            >
              {t('checkout')}
            </button>
          </div>

          <CartContent />
          <div className='flex justify-end pt-10'>
            <button
              className={Styles.button}
              onClick={() => {
                navigate('/checkout')
              }}
            >
              {t('checkout')}
            </button>
          </div>
          {/* <DisplayDeliveryCharges /> */}
        </div>
      </Page>
    </>
  )
}
