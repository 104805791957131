import { useForm } from 'react-hook-form'
import { useAuthState, useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import * as Yup from 'yup'
import { InputText } from '../../base/InputText'
import { Card } from '../../base/Card'
import { Page } from '../../base/Page'
import { Title } from '../../base/Title'
import { Styles } from '../../base/Styles'
import { useYupValidationResolver } from '../../base/validateResolver'
import { useEffect } from 'react'
import { Alert, Button } from 'flowbite-react'
import { IoAlertOutline } from 'react-icons/io5'
import { AuthError } from 'firebase/auth'

const formSchema = Yup.object().shape({
  email: Yup.string().required('error.required').email('error.invalidEmail'),
})

export default function ForgotPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(formSchema) })

  const [user] = useAuthState(auth)
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth)

  console.log('forgotPassword email', error)

  const onSubmit = (value: any) => {
    if (value.email) {
      const actionCodeSettings = {
        url: 'http://localhost:3000/resetPassword',
      }

      sendPasswordResetEmail(value.email, actionCodeSettings)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <>
      <Page>
        <Card>
          <div className='p-2 space-y-2 md:space-y-2 sm:p-8'>
            <Title>{t('doyouforgotpassword')}</Title>
            {(error as AuthError)?.code && (
              <Alert color='failure' icon={IoAlertOutline}>
                {t((error as AuthError)?.code)}
              </Alert>
            )}
            <form className='flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputText
                  {...register('email')}
                  name={'email'}
                  label={t('yourEmail') ?? 'Email'}
                  placeholder='name@royalbakes.jp'
                  error={t(errors?.email?.message as string) ?? undefined}
                />
              </div>

              <Button type='submit'>{t('resetPassword')}</Button>
              <span className={Styles.textSm}>
                {t('doYouHaveAnAccount')}{' '}
                <Link to='/signin' className={Styles.link}>
                  {t('signIn')}
                </Link>
              </span>
            </form>
          </div>
        </Card>
      </Page>
    </>
  )
}
