import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Product } from '../../model/Product'

export function ProductCard({ product }: { product: Product }) {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  return (
    <>
      <div className='w-full min-w-[13rem] max-w-[13rem] bg-rb-background rounded-lg shadow-md hover:shadow-sm dark:bg-gray-800 dark:border-gray-700'>
        <a
          href={'/product/' + product?.productId}
          onClick={(e) => {
            e.preventDefault()
            navigate('/product/' + product?.productId)
          }}
        >
          <ProductImage product={product} />
        </a>
        <div className='px-5 pb-5 flex flex-col content-between h-full'>
          <a
            href={'/product/' + product?.productId}
            onClick={(e) => {
              e.preventDefault()
              navigate('/product/' + product?.productId)
            }}
          >
            <h5 className='text-lg font-semibold tracking-tight text-gray-900 dark:text-white'>
              {i18n.language === 'en' ? product.productNameEn : product.productNameJp}
            </h5>
          </a>

          <div className='flex w-full justify-end'>
            {/* <span className='text-xl font-bold text-gray-900 dark:text-white'>$599</span>
            <a
              href='#'
              className='text-white bg-rb-purple hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Add to cart
            </a> */}
            <a
              href={'/product/' + product.productId}
              onClick={(e) => {
                e.preventDefault()
                navigate('/product/' + product?.productId)
              }}
              className='underline float-right'
            >
              {t('view')}
            </a>
          </div>
        </div>
      </div>
    </>
  )

  function ProductImage({ product }: { product: Product }) {
    let img: string | undefined = undefined
    if (product?.images && Object.values(product?.images)) {
      img = Object.values(product?.images)[0] as string
    }
    return (
      <>
        <div className='p-2  aspect-square'>
          {img && <img className='rounded aspect-square ' src={img} alt='...' />}
        </div>
      </>
    )
  }
}
