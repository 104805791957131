export const DB = {
  users: 'users',
  cart: (userId?: string) => `users/${userId}/shoppingCart`,
  currentOrders: 'currentOrders',
  categories: 'settings/categories',
  orderProducts: 'orderProducts',
  deliveryTimeslots: 'settings/deliveryTimeslots',
  deliveryCharges: 'settings/deliveryCharges',
  deliveryLocations: 'settings/deliveryLocations',
  pickupTimeslots: 'settings/pickupTimeslots',
  landmarks: 'settings/landmarks',
  settings: 'settings/otherSettings',
  products: 'products',
  productPricing: 'productPricing',
}
