import { useAuthState, useSendEmailVerification } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { Card } from '../base/Card'
import { Page } from '../base/Page'
import { Title } from '../base/Title'
import { Styles } from '../base/Styles'
import { useEffect } from 'react'
import { Button } from 'flowbite-react'

export default function EmailVerification() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [user] = useAuthState(auth)
  const [sendEmailVerification] = useSendEmailVerification(auth)

  useEffect(() => {
    if (user?.emailVerified) {
      navigate('/')
    }
  }, [user])

  const resend = () => {
    if (user?.email) {
      sendEmailVerification()
    }
  }

  return (
    <>
      <Page>
        <Card className='md:w-2/3'>
          <div className='p-2 space-y-2 md:space-y-2 sm:p-8'>
            <Title>{t('emailVerification')}</Title>
            <span className={`${Styles.textSm} flex flex-1 gap-2`}>
              {t('resendemailverification', { email: `${user?.email}` })}{' '}
              <Button color='light' pill={true} onClick={resend} className={Styles.link}>
                {t('resend')}
              </Button>
            </span>
          </div>
        </Card>
      </Page>
    </>
  )
}
