import { Alert, Button, Card, Checkbox, Label } from 'flowbite-react'
import {
  useAuthState,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
} from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { IoAlertOutline } from 'react-icons/io5'
import { FcGoogle } from 'react-icons/fc'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { useYupValidationResolver } from '../../base/validateResolver'
import { InputText } from '../../base/InputText'
import { Styles } from '../../base/Styles'

const formSchema = Yup.object().shape({
  email: Yup.string().required('error.required').email('error.invalidEmail'),
  password: Yup.string()
    .required('error.required')
    .min(6, 'error.passwordshouldbeatleast6characters')
    .max(12, 'error.PasswordcannotexceedMoreThan12characters'),
})
export default function SignIn() {
  const [signInWithGoogle] = useSignInWithGoogle(auth)
  const [signInWithEmailAndPassword, _user, _loading, errorSignIn] =
    useSignInWithEmailAndPassword(auth)
  const { t } = useTranslation()
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(formSchema) })

  if (user) {
    navigate('/')
  }

  const onSubmit = (value: any) => {
    console.log(value)
    signInWithEmailAndPassword(value.email, value.password)
      .then((res) => {
        console.log('--res', res)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  if (loading) {
    return (
      <div>
        <div>Initializing User...</div>
      </div>
    )
  }
  if (error) {
    return <>Error: {error}</>
  }

  return (
    <>
      <section className='flex h-full'>
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0 '>
          <Card className='bg-rb-background'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
                {t('signIn')}
              </h1>
              {errorSignIn?.code && (
                <Alert color='failure' icon={IoAlertOutline}>
                  {t(errorSignIn?.code)}
                </Alert>
              )}
              <form className='flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <InputText
                    {...register('email')}
                    name={'email'}
                    label={t('yourEmail') ?? 'Email'}
                    placeholder='name@royalbakes.jp'
                    error={t(errors?.email?.message as string) ?? undefined}
                  />
                </div>
                <div>
                  <InputText
                    {...register('password')}
                    name={'password'}
                    type='password'
                    label={t('yourPassword') ?? 'Password'}
                    error={t(errors?.password?.message as string) ?? undefined}
                  />
                </div>
                <div className='flex items-center gap-2'>
                  <Checkbox id='remember' />
                  <Label htmlFor='remember'>{t('rememberMe')}</Label>
                </div>
                <button
                  type='submit'
                  className='bg-rb-purple text-white py-2 px-4 rounded font-bold hover:bg-rb-purple-dark'
                >
                  {t('signIn')}
                </button>

                <div className='flex gap-1'>
                  <button
                    className='bg-white  w-full flex items-center justify-center gap-2 p-2 rounded-md shadow-md hover:bg-gray-100 font-bold'
                    onClick={() => signInWithGoogle()}
                  >
                    <FcGoogle size='2em' />
                    {t('signInGoogle')}
                  </button>
                </div>

                <span className={Styles.textSm}>
                  {t('dontHaveAnAccountYet')}{' '}
                  <Link to='/signup' className={Styles.link}>
                    {t('signUp')}
                  </Link>
                </span>
                <Alert color='warning'>
                  {t('doyouforgotpassword')}{' '}
                  <Link to='/forgotPassword' className={Styles.link}>
                    {t('forgotpassword')}
                  </Link>
                </Alert>
              </form>
            </div>
          </Card>
        </div>
      </section>
    </>
  )
}
