import React, { forwardRef } from 'react'
interface PageProps {
  children: React.ReactNode
}
type PageRef = React.ForwardedRef<HTMLElement>
// eslint-disable-next-line react/display-name
export const Page = forwardRef(({ children, ...props }: PageProps, ref: PageRef) => {
  return (
    <section className='flex w-[90%] items-center justify-center bg-rb-background-dark'>
      <div className='container flex flex-col items-center justify-center mx-auto w-full h-full '>
        {children}
      </div>
    </section>
  )
})
