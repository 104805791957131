import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEnglish from './en.json'
import translationJapanese from './ja.json'

const resources = {
  en: {
    translation: translationEnglish,
  },
  ja: {
    translation: translationJapanese,
  },
}

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  debug: false,
  lng: 'en', // if you're using a language detector, do not define the lng option
})

export default i18next
