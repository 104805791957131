import { create } from 'zustand';
import { ProductOrder } from '../model/Order';
import { DeliveryCharge, OtherSettings } from '../model/Settings';

export interface CartStore {
    items: ProductOrder[];
    subTotalAmount: number;
    totalTaxAmount: number;
    totalAmount: number;
    taxAmountByRate: Map<number, number>;
    addToCart: (item: ProductOrder) => void;
    removeFromCart: (item: ProductOrder) => void;
    clearCart: () => void;
    setQuantity: (item: ProductOrder, quantity: number) => void;
}

// const getDeliveryCharge = (totalAmount: number, deliveryCharges: DeliveryCharge[]) => {
//     const deliveryCharge = deliveryCharges.find((d) => d.range >= totalAmount)
//     return deliveryCharge?.deliveryCharge ?? 0
// }

const getTotalAndTax = (
    productOrders: ProductOrder[],
    // otherSettings: OtherSettings,
    // isDeliveryChangesApplicable: boolean,
    // deliveryCharges?: DeliveryCharge[],
) => {
    // calculate subTotalAmount, taxAmount, deliveryCharge, deliveryChargeTaxAmount
    let subTotalAmount = 0
    let totalTaxAmount = 0
    const taxAmountByRate = new Map<number, number>()
    // let deliveryCharge = 0
    // let deliveryChargeTaxAmount = 0

    productOrders.forEach((p) => {
        const taxRate = (p.taxRate ?? 0) * 0.01
        const totalPrice = p?.price * p?.quantity

        const taxAmount = totalPrice > 0 ? (totalPrice / (1 + taxRate)) * taxRate : 0
        totalTaxAmount += taxAmount
        subTotalAmount += totalPrice
        const taxAmountByRateValue = taxAmountByRate.get(p.taxRate ?? 0) ?? 0
        taxAmountByRate.set(p.taxRate ?? 0, taxAmountByRateValue + taxAmount)
    })

    // if (isDeliveryChangesApplicable) {
    //     deliveryCharge = getDeliveryCharge(subTotalAmount + totalTaxAmount, deliveryCharges ?? [])
    //     deliveryChargeTaxAmount = deliveryCharge * otherSettings?.shippingTax ?? 0 * 0.01
    // }

    // const totalAmount =
    //     Number(subTotalAmount) + Number(deliveryCharge) + Number(deliveryChargeTaxAmount)

    const totalAmount = Number(subTotalAmount)

    return {
        subTotalAmount,
        totalTaxAmount,
        taxAmountByRate,
        // deliveryCharge,
        // deliveryChargeTaxAmount,
        totalAmount,
    }
}

const LOCAL_STORAGE_KEY = 'cart'

const getInitialState = () => {
    const items = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]') as ProductOrder[]
    const { subTotalAmount, totalTaxAmount, taxAmountByRate, totalAmount } = getTotalAndTax(items ?? [])
    return {
        items: items ?? [],
        subTotalAmount,
        totalTaxAmount,
        taxAmountByRate,
        totalAmount,
    }
}

const setCart = (set: any, items: ProductOrder[]) => {
    const { subTotalAmount, totalTaxAmount, taxAmountByRate, totalAmount } = getTotalAndTax(items);
    set(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(items))
        return {
            items,
            subTotalAmount,
            totalTaxAmount,
            taxAmountByRate,
            totalAmount,
        }
    });
}

export const useCartStore = create<CartStore>(
    (set, get) => ({
        items: getInitialState().items,
        subTotalAmount: getInitialState().subTotalAmount,
        totalTaxAmount: getInitialState().totalTaxAmount,
        totalAmount: getInitialState().totalAmount,
        taxAmountByRate: getInitialState().taxAmountByRate,
        deliveryCharge: 0,
        deliveryChargeTaxAmount: 0,
        otherSettings: {} as OtherSettings,
        deliveryCharges: [] as DeliveryCharge[],

        addToCart: (item: ProductOrder) => {
            const { items } = get();
            const existingItem = items.find(
                (i) => i.pricingId === item.pricingId && i.productId === item.productId
            );
            if (existingItem) {
                const itemsUpdated = items.map((i) =>
                    i.pricingId === item.pricingId && i.productId === item.productId ? { ...i, quantity: item.quantity } : i);
                setCart(set, itemsUpdated)
            } else {
                const itemsUpdated = [...items, item];
                setCart(set, itemsUpdated)
            }
        },
        removeFromCart: (item: ProductOrder) => {
            const { items } = get();

            const itemsRemaining = items.filter((i) => i.id !== item.id);
            setCart(set, itemsRemaining)
        },
        clearCart: () => setCart(set, []),

        setQuantity: (item: ProductOrder, quantity: number) => {
            const { items } = get();
            if (quantity <= 0) {
                const itemsRemaining = items.filter((i) => i.id !== item.id);
                setCart(set, itemsRemaining)
                return;
            }
            const itemsUpdated = items.map((i) =>
                i.pricingId === item.pricingId && i.productId === item.productId ? { ...i, quantity: quantity } : i);
            setCart(set, itemsUpdated)
        },
    }),

);