import React from 'react'
import { IoLogoWhatsapp } from 'react-icons/io5'

export default function Contacts() {
  return (
    <>
      <div className='flex flex-col bg-rb-background rounded-lg shadow-md p-3 w-full max-w-xl md:min-w-1/4 md:max-w-3/4 mt-5'>
        <div className='flex w-full items-center justify-center py-3 text-2xl'>Contacts</div>
        <div className='flex flex-col p-3'>
          <div className='flex flex-col w-full justify-center items-center gap-5'>
            <div className='flex w-full gap-2'>
              <span className='font-bold'>Phone:</span>{' '}
              <span className='font-bold underline underline-offset-4'>
                <a href='tel:080-3500-9515'>080-3500-9515</a>
              </span>
            </div>
            <div className='flex w-full'>
              <span className='font-bold flex gap-2 underline underline-offset-4'>
                <IoLogoWhatsapp className='text-green-500' size={'1.6em'} />
                <a href='https://wa.me/818035009515'>080-3500-9515</a>
              </span>
            </div>
            <div className='flex w-full'>
              <span className='font-bold underline underline-offset-4 flex flex-col gap-2'>
                <div className='flex gap-2'>
                  <IoLogoWhatsapp className='text-green-500' size={'1.6em'} />
                  <a href='https://chat.whatsapp.com/HmRgZtHvPVHGg9EB6YVref'>Whatsapp group</a>
                </div>
                <a href='https://chat.whatsapp.com/HmRgZtHvPVHGg9EB6YVref'>
                  https://chat.whatsapp.com/HmRgZtHvPVHGg9EB6YVref
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
