import React from 'react'

export default function Loading() {
  return (
    <div className='flex w-full h-40 items-center justify-center'>
      <div className='flex w-32 bg-rb-background justify-center items-center rounded h-9'>
        <div className='text-center'>Loading...</div>
      </div>
    </div>
  )
}
