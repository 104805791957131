import { useForm } from 'react-hook-form'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import * as Yup from 'yup'
import { InputText } from '../../base/InputText'
import { Card } from '../../base/Card'
import { Page } from '../../base/Page'
import { Title } from '../../base/Title'
import { Styles } from '../../base/Styles'
import { useYupValidationResolver } from '../../base/validateResolver'
import { useEffect } from 'react'
import { Button } from 'flowbite-react'

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('error.required')
    .min(6, 'error.passwordshouldbeatleast6characters')
    .max(12, 'error.PasswordcannotexceedMoreThan12characters'),
  confirmPassword: Yup.string()
    .required('error.required')
    .min(6, 'error.passwordshouldbeatleast6characters')
    .max(12, 'error.PasswordcannotexceedMoreThan12characters')
    .oneOf([Yup.ref('password')], 'error.Passwordsdonotmatch'),
})

export default function ResetPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(formSchema) })

  const [user] = useAuthState(auth)

  const onSubmit = (value: any) => {
    console.log(value)
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <>
      <Page>
        <Card>
          <div className='p-2 space-y-2 md:space-y-2 sm:p-8'>
            <Title>{t('doyouforgotpassword')}</Title>
            {/* {signUpError?.code && (
              <Alert color='failure' icon={IoAlertOutline}>
                {t(signUpError?.code)}
              </Alert>
            )} */}
            <form className='flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputText
                  {...register('password')}
                  name={'password'}
                  type='password'
                  label={t('yourPassword') ?? 'Password'}
                  error={t(errors?.password?.message as string) ?? undefined}
                />
              </div>
              <div>
                <InputText
                  {...register('confirmPassword')}
                  name={'confirmPassword'}
                  type='password'
                  label={t('confirmPassword') ?? 'Confirm Password'}
                  error={t(errors?.confirmPassword?.message as string) ?? undefined}
                />
              </div>

              <Button type='submit'>{t('resetPassword')}</Button>
              <span className={Styles.textSm}>
                {t('doYouHaveAnAccount')}{' '}
                <Link to='/signin' className={Styles.link}>
                  {t('signIn')}
                </Link>
              </span>
            </form>
          </div>
        </Card>
      </Page>
    </>
  )
}
