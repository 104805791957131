import React from 'react'

export default function Services() {
  return (
    <>
      <h1>Services</h1>
      <div>Birthday Cakes</div>
    </>
  )
}
