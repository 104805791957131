import { Outlet } from 'react-router-dom'
import Header from './Header'

export default function Layout() {
  return (
    <div className='flex flex-col h-full px-1 bg-rb-background-dark dark:bg-gray-900 w-full font-Montserrat text-gray-800'>
      <Header />
      <div className='flex flex-col h-full sm:px-0 md:p-1 px-2 pt-1 w-auto items-center justify-center'>
        <Outlet />
      </div>
    </div>
  )
}
