import { child, get, getDatabase, push, ref, serverTimestamp, set } from 'firebase/database'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { Product, ProductPricing } from '../model/Product'
import { Order, ProductOrder } from '../model/Order'
import { DBContext } from './DbContext'
import { OtherSettings } from '../model/Settings'
import { useCartStore } from './CartStore'

const database = getDatabase(firebaseApp)

// const getDeliveryCharge = (totalAmount: number, deliveryCharges: DeliveryCharge[]) => {
//   const deliveryCharge = deliveryCharges.find((d) => d.range >= totalAmount)
//   return deliveryCharge?.deliveryCharge ?? 0
// }

// const getTotalAndTax = (
//   productOrders: DataSnapshot[],
//   otherSettings: OtherSettings,
//   isDeliveryChangesApplicable: boolean,
//   deliveryCharges?: DeliveryCharge[],
// ) => {
//   // calculate subTotalAmount, taxAmount, deliveryCharge, deliveryChargeTaxAmount
//   let subTotalAmount = 0
//   let totalTaxAmount = 0
//   const taxAmountByRate = new Map<number, number>()
//   let deliveryCharge = 0
//   let deliveryChargeTaxAmount = 0

//   productOrders.forEach((po) => {
//     const p = po.val() as ProductOrder
//     const taxRate = (p.taxRate ?? 0) * 0.01
//     subTotalAmount += p?.price * p?.quantity

//     const taxAmount = subTotalAmount > 0 ? (subTotalAmount / (1 + taxRate)) * taxRate : 0
//     totalTaxAmount += taxAmount
//     const taxAmountByRateValue = taxAmountByRate.get(p.taxRate ?? 0) ?? 0
//     taxAmountByRate.set(p.taxRate ?? 0, taxAmountByRateValue + taxAmount)
//   })

//   if (isDeliveryChangesApplicable) {
//     deliveryCharge = getDeliveryCharge(subTotalAmount + totalTaxAmount, deliveryCharges ?? [])
//     deliveryChargeTaxAmount = deliveryCharge * otherSettings?.shippingTax ?? 0 * 0.01
//   }

//   const totalAmount =
//     Number(subTotalAmount) + Number(deliveryCharge) + Number(deliveryChargeTaxAmount)

//   return {
//     subTotalAmount,
//     totalTaxAmount,
//     taxAmountByRate,
//     deliveryCharge,
//     deliveryChargeTaxAmount,
//     totalAmount,
//   }
// }

// export const useDbCartHook = () => {
//   const [authUser] = useAuthState(auth)

//   const [cartProductSnapshots, setCartProductSnapshots] = useState<DataSnapshot[]>([])
//   const cartProductsListener = useMemo(() => ref(database, DB.cart(authUser?.uid)), [authUser?.uid])

//   useEffect(() => {
//     let _onChildAdded: any = undefined
//     let _onChildChanged: any = undefined
//     let _onChildRemoved: any = undefined

//     const cartProductSnapshotsMap = new Map<string | null, DataSnapshot>()

//     setTimeout(() => {
//       if (cartProductsListener) {
//         _onChildAdded = onChildAdded(cartProductsListener, (snapshot) => {
//           cartProductSnapshotsMap.set(snapshot.key, snapshot)
//           setCartProductSnapshots([...cartProductSnapshotsMap.values()])
//         })
//         _onChildChanged = onChildChanged(cartProductsListener, (snapshot) => {
//           cartProductSnapshotsMap.set(snapshot.key, snapshot)
//           setCartProductSnapshots([...cartProductSnapshotsMap.values()])
//         })
//         _onChildRemoved = onChildRemoved(cartProductsListener, (snapshot) => {
//           cartProductSnapshotsMap.delete(snapshot.key)
//           setCartProductSnapshots([...cartProductSnapshotsMap.values()])
//         })
//       }
//     }, 1000)

//     return () => {
//       if (cartProductsListener) {
//         off(cartProductsListener, 'child_added', _onChildAdded)
//         off(cartProductsListener, 'child_changed', _onChildChanged)
//         off(cartProductsListener, 'child_removed', _onChildRemoved)
//       }
//     }
//   }, [authUser?.uid, cartProductsListener])
//   return { cartProductSnapshots }
// }

export const useCartHook = ({
  product,
  productPricing,
}: {
  product?: Product
  productPricing?: ProductPricing
}) => {
  const {
    addToCart: add,
    clearCart,
    items,
    setQuantity,
    subTotalAmount,
    taxAmountByRate,
    totalAmount,
    totalTaxAmount,
  } = useCartStore()
  const { authUser } = useContext(DBContext)
  const [cartProduct, setCartProduct] = useState<ProductOrder | undefined>(undefined)
  // const { cart: cartProductSnapshots, selectedTimeslot } = useContext(DBContext)

  // const { otherSettings } = useOtherSettings()
  // const { deliveryCharges } = useDeliveryCharges()
  const { user } = useContext(DBContext)

  useEffect(() => {
    if (items && product && productPricing) {
      const cartProduct = items.find(
        (p) => p && p.pricingId === productPricing.pricingId && p.productId === product.productId,
      )
      setCartProduct(cartProduct)
    }
  }, [items, product, productPricing])

  // const [subTotalAmount, setSubTotalAmount] = useState<number>(0)
  // const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0)
  // const [taxAmountByRate, setTaxAmountByRate] = useState<Map<number, number>>(new Map())
  // const [deliveryCharge, setDeliveryCharge] = useState<number>(0)
  // const [deliveryChargeTax, setDeliveryChargeTax] = useState<number>(0)
  // const [totalAmount, setTotalAmount] = useState<number>(0)

  // useEffect(() => {
  //   // if (cartProductSnapshots && otherSettings) {
  //   if (cartProductSnapshots) {
  //     const {
  //       subTotalAmount,
  //       totalTaxAmount,
  //       taxAmountByRate,
  //       deliveryCharge,
  //       deliveryChargeTaxAmount,
  //       totalAmount,
  //     } = getTotalAndTax(
  //       cartProductSnapshots,
  //       // otherSettings,
  //       selectedTimeslot?.type === 'delivery' ?? false,
  //       // deliveryCharges,
  //     )
  //     setSubTotalAmount(subTotalAmount)
  //     setTotalTaxAmount(totalTaxAmount)
  //     setTaxAmountByRate(taxAmountByRate)
  //     setDeliveryCharge(deliveryCharge)
  //     setDeliveryChargeTax(deliveryChargeTaxAmount)
  //     setTotalAmount(totalAmount)
  //   }
  // }, [cartProductSnapshots, otherSettings, selectedTimeslot, deliveryCharges])

  const addToCart = useCallback(
    (product: Product, productPricing: ProductPricing, quantity = 1) => {
      if (product && productPricing) {
        let productOrder = items?.find(
          (p) => p && p.pricingId === productPricing.pricingId && p.productId === product.productId,
        )

        if (!productOrder) {
          productOrder = new ProductOrder()
          // short uuid
          productOrder.id = Math.random().toString(36).substring(2, 15)
          productOrder.additionDate = new Date().toISOString()
          productOrder.productId = product.productId
          productOrder.pricingId = productPricing.pricingId
          productOrder.nameEn = product.productNameEn
          productOrder.nameJp = product.productNameJp
          productOrder.unit = productPricing.unit
          productOrder.price = productPricing.price
          productOrder.quantity = quantity
          productOrder.comments = ''
          productOrder.thumbImage = product.images ? product.images['0'] : ''
          productOrder.taxRate = product.taxRate
          productOrder.userId = authUser?.uid
          delete productOrder.orderId
          add(productOrder)
          // const newKey = push(child(ref(database), DB.cart(authUser?.uid))).key
          // productOrder.key = newKey
        } else {
          // productOrder.quantity = quantity
          setQuantity(productOrder, quantity)
        }
      }
    },
    [],
  )

  // const removeFromCart = useCallback((productOrderSnapshot: DataSnapshot) => {
  //   remove(productOrderSnapshot.ref)
  // }, [])

  // const addQuantityCart = useCallback((productOrderSnapshot: DataSnapshot) => {
  //   if (productOrderSnapshot) {
  //     const productOrder = productOrderSnapshot.val() as ProductOrder
  //     productOrder.quantity++
  //     set(ref(database, DB.cart(authUser?.uid) + '/' + productOrder.key), productOrder)
  //   }
  // }, [])

  // const reduceQuantityCart = useCallback((productOrderSnapshot: DataSnapshot) => {
  //   if (productOrderSnapshot) {
  //     const productOrder = productOrderSnapshot.val() as ProductOrder
  //     productOrder.quantity--
  //     if (productOrder.quantity < 0) {
  //       productOrder.quantity = 0
  //     }
  //     set(ref(database, DB.cart(authUser?.uid) + '/' + productOrder.key), productOrder)
  //   }
  // }, [])

  // const updateCart = useCallback(() => {
  //   console.log(' update cart')
  // }, [])

  // const clearCart = useCallback(() => {
  //   if (cartProductSnapshots && cartProductSnapshots.length > 0) {
  //     const parentRef = cartProductSnapshots[0].ref.parent?.ref
  //     if (parentRef) {
  //       remove(parentRef)
  //     }
  //   }
  // }, [])

  const submitOrder = useCallback((otherSettings: OtherSettings) => {
    if (!authUser?.uid || items?.length === 0 || !otherSettings) {
      return
    }
    // const {
    //   subTotalAmount,
    //   totalTaxAmount,
    //   taxAmountByRate,
    //   deliveryCharge,
    //   deliveryChargeTaxAmount,
    //   totalAmount,
    // } = getTotalAndTax(
    //   cartProductSnapshots,
    //   otherSettings,
    //   selectedTimeslot?.type === 'delivery' ?? false,
    //   deliveryCharges,
    // )

    const order = new Order()
    order.orderDate = serverTimestamp()
    order.orderStatus = 'pending'
    order.orderId = push(child(ref(database), DB.currentOrders)).key
    order.userId = authUser?.uid
    order.invoiceId = ''
    order.invoiceDate = ''
    order.cancelledDate = ''
    order.cancelReason = ''

    order.subTotal = subTotalAmount
    order.taxes = Array.from(taxAmountByRate, ([key, value]) => ({ rate: key, amount: value }))
    order.taxAmount = totalTaxAmount
    order.deliveryCharge = 0 // deliveryCharge
    order.deliveryChargeTax = 0 // otherSettings?.shippingTax
    order.deliveryChargeTaxAmount = 0 // deliveryChargeTax
    order.totalAmount = totalAmount

    if (order.deliveryChargeTax === undefined) {
      delete order.deliveryChargeTax
    }

    order.paidAmount = 0
    order.paidMethod = ''
    order.balanceAmount = totalAmount

    order.deliveryTimeslot = '' // selectedTimeslot?.type === 'delivery' ? selectedTimeslot?.valueEn : ''
    order.deliveryDate = '2020-12-12'
    order.deliveryAddress = user?.info?.locationAddressEn + ' ' + user?.info?.address
    order.deliveryName = user?.info?.firstName + ' ' + user?.info?.lastName
    order.deliveryPhone = user?.info?.phoneNumber
    order.pickupTimeslot = '' // selectedTimeslot?.type === 'pickup' ? selectedTimeslot?.valueEn : ''

    order.comments = 'test comments'

    console.log('order', order)

    if (!order.orderId || !order.totalAmount || !order.subTotal || !order.taxAmount) {
      return
    }

    set(ref(database, DB.currentOrders + '/' + order.orderId), order)

    // move cart products to order products
    items?.forEach((productOrder) => {
      // const productOrder = productOrderSnapshot.val() as ProductOrder
      productOrder.orderId = order.orderId
      productOrder.userId = authUser.uid
      productOrder.key = productOrder.id

      set(
        ref(database, DB.orderProducts + '/' + order.orderId + '/' + productOrder.id),
        productOrder,
      )
      // reduce product quantity
      if (productOrder.productId) {
        const productRef = ref(
          database,
          DB.productPricing + '/' + productOrder.productId + '/' + productOrder.pricingId,
        )
        get(productRef).then((snapshot) => {
          const product = snapshot.val() as ProductPricing
          if (product) {
            product.stock = Number(product.stock) - productOrder.quantity
            set(productRef, product)
          }
        })
      }
    })
    clearCart()
  }, [])

  return {
    addToCart,
    setQuantity,
    // updateCart,
    // addQuantityCart,
    // reduceQuantityCart,
    clearCart,
    // removeFromCart,
    // setDeliveryCharge,
    submitOrder,
    // cartProductSnapshots,
    subTotalAmount,
    totalTaxAmount,
    taxAmountByRate,
    // deliveryCharge,
    // deliveryChargeTax,
    totalAmount,
    cartProduct,
  }
}
