import React, { forwardRef } from 'react'
interface CardProps {
  children: React.ReactNode
  className?: string
}
type CardRef = React.ForwardedRef<HTMLElement>
// eslint-disable-next-line react/display-name
export const Card = forwardRef(({ children, className, ...props }: CardProps, ref: CardRef) => {
  return (
    <div
      className={
        'p-6 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 ' +
        className
      }
    >
      {children}
    </div>
  )
})
