import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Page } from '../../base/Page'
import { ProductCard } from './ProductCard'
import { useCategory, useProductsByCategoryId } from '../../base/useDbHooks'
import { useEffect } from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../firebase'

export default function ProductsList() {
  const { i18n } = useTranslation()
  const { category: catId } = useParams()

  const { category } = useCategory(catId)
  const { products } = useProductsByCategoryId(catId)

  useEffect(() => {
    if (products && category) {
      logEvent(analytics, 'view_item_list', {
        // eslint-disable-next-line camelcase
        item_list_id: catId,
        // eslint-disable-next-line camelcase
        item_list_name: category.nameEn,
      })
    }
  }, [products])

  return (
    <>
      <Page>
        <div className='flex flex-col gap-5 w-full py-4 rounded-lg dark:bg-gray-800 dark:border-gray-700 items-center justify-center'>
          <div className='text-2xl font-normal text-center'>
            {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
          </div>
        </div>
        <div className='flex flex-col  bg-rb-background-dark w-screen'>
          {products && (
            <>
              <div className='flex flex-auto gap-1 p-1 overflow-y-hidden ove  justify-center bg-rb-background-dark my-1 '>
                {products?.map((p) => (
                  <ProductCard key={p.productId} product={p} />
                ))}
              </div>
            </>
          )}
        </div>
      </Page>
    </>
  )
}
