import {
  DataSnapshot,
  getDatabase,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  set,
} from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { ProductPricing } from '../model/Product'

const database = getDatabase(firebaseApp)

export const useProductPricing = (productId?: string) => {
  const productPricingSnapshot = useMemo(
    () => ref(database, DB.productPricing + '/' + productId),
    [productId],
  )
  const [productPricingSnapshots, setProductPricingSnapshots] = useState<DataSnapshot[]>([])

  useEffect(() => {
    let _onChildAdded: any = undefined
    let _onChildChanged: any = undefined
    let _onChildRemoved: any = undefined

    const productPricingSnapshotsMap = new Map<string | null, DataSnapshot>()

    setTimeout(() => {
      _onChildAdded = onChildAdded(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.set(snapshot.key, snapshot)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
      _onChildChanged = onChildChanged(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.set(snapshot.key, snapshot)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
      _onChildRemoved = onChildRemoved(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.delete(snapshot.key)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
    }, 500)

    return () => {
      off(productPricingSnapshot, 'child_added', _onChildAdded)
      off(productPricingSnapshot, 'child_changed', _onChildChanged)
      off(productPricingSnapshot, 'child_removed', _onChildRemoved)
    }
  }, [productId])

  const reduceStock = useCallback((productPricing: ProductPricing, reduceBy = 1) => {
    // productPricing.stock = productPricing.stock - reduceBy
    set(productPricingSnapshot.ref, productPricing)
  }, [])

  const increaseStock = useCallback((productPricingSnapshot: DataSnapshot, increaseBy = 1) => {
    const productPricing = productPricingSnapshot.val()
    productPricing.stock = productPricing.stock + increaseBy
    set(productPricingSnapshot.ref, productPricing)
  }, [])

  return { productPricingSnapshots, reduceStock, increaseStock }
}
