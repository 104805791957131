import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline, IoCloseOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useCartHook } from '../../base/useCartHook'
import { useOtherSettings } from '../../base/useDbHooks'
import { useCartStore } from '../../base/CartStore'
import toast from 'react-hot-toast'

// eslint-disable-next-line react/display-name, no-empty-pattern
export const CartContent = forwardRef((props, ref): JSX.Element => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { otherSettings } = useOtherSettings()
  const { items, setQuantity } = useCartStore()

  const {
    // cartProductSnapshots,
    // removeFromCart,
    submitOrder,
    taxAmountByRate,
    // deliveryCharge,
    // deliveryChargeTax,
    totalAmount,
  } = useCartHook({})

  const handleCheckout = () => {
    if (otherSettings) {
      submitOrder(otherSettings)
      toast.success(t('toast_order_success'))
      navigate('/')
    }
  }

  useImperativeHandle(ref, () => ({
    handleCheckout,
  }))

  return (
    <>
      <table className='table-auto m->3 rounded-lg'>
        <thead className='border-b'>
          <tr>
            <th className='sm:hidden'></th>
            <th className='w-52 p-3'>{t('product')}</th>
            <th>{t('price')}</th>
            <th className='w-12 text-center'>{t('quantity')}</th>
            <th className='w-20 text-right'>{t('total')}</th>
            <th className='w-10'></th>
          </tr>
        </thead>
        <tbody>
          {items?.map((p) => {
            return (
              <>
                <tr className='border-b'>
                  <td className='p-3 sm:hidden sm:p-0'>
                    <a
                      className='cursor-pointer'
                      href={'/product/' + p?.productId}
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/product/' + p?.productId)
                      }}
                    >
                      <img src={p.thumbImage} className='rounded w-14' />
                    </a>
                  </td>
                  <td className='p-3 flex flex-col justify-center items-center cursor-pointer'>
                    <a
                      className='font-semibold '
                      href={'/product/' + p?.productId}
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/product/' + p?.productId)
                      }}
                    >
                      {i18n.language === 'en' ? p.nameEn : p.nameJp}
                    </a>
                    <div className='font-thin'>{p.unit}</div>
                  </td>
                  <td className='text-right'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP').format(p.price)}
                  </td>
                  <td className='text-right '>
                    <div className='px-3 flex items-baseline gap-2'>
                      <div
                        className='flex justify-center cursor-pointer'
                        onClick={() => {
                          setQuantity(p, p.quantity - 1)
                        }}
                      >
                        <IoRemoveCircleOutline />
                      </div>{' '}
                      {p.quantity}
                      <div
                        className='flex justify-center cursor-pointer'
                        onClick={() => {
                          setQuantity(p, p.quantity + 1)
                        }}
                      >
                        <IoAddCircleOutline />
                      </div>
                    </div>
                  </td>
                  <td className='text-right'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP').format(p.quantity * p.price)}
                  </td>
                  <th>
                    <div
                      className='flex justify-center cursor-pointer'
                      onClick={() => {
                        setQuantity(p, 0)
                      }}
                    >
                      <IoCloseOutline size={'0.9em'} />
                    </div>
                  </th>
                </tr>
              </>
            )
          })}
        </tbody>
        <tfoot>
          {Array.from(taxAmountByRate.entries())?.map((tax) => {
            const [taxRate, taxAmount] = tax
            return (
              <>
                <tr>
                  <td className='text-right text-sm' colSpan={3}>
                    {t('tax')} {taxRate}%
                  </td>
                  <td colSpan={2} className='text-right text-sm'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(taxAmount)}
                  </td>
                </tr>
              </>
            )
          })}
          {/* {deliveryCharge > 0 && (
            <tr>
              <td className='text-right text-sm pt-6' colSpan={3}>
                {t('deliveryCharge')}
              </td>
              <td colSpan={2} className='text-right text-sm pt-6'>
                <span className='font-thin text-xs'>¥</span>
                {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                  deliveryCharge,
                )}
              </td>
            </tr>
          )}
          {deliveryChargeTax > 0 && (
            <tr>
              <td className='text-right text-sm pt-6' colSpan={3}>
                {t('deliveryChargeTax')} {otherSettings?.shippingTax}%
              </td>
              <td colSpan={2} className='text-right text-sm pt-6'>
                <span className='font-thin text-xs'>¥</span>
                {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                  deliveryChargeTax,
                )}
              </td>
            </tr>
          )} */}
          <tr>
            <td></td>
            <td></td>
            <td className='text-right pb-4'>{t('total')}</td>
            <td colSpan={2} className='text-right font-semibold pb-4'>
              <span className='font-thin text-xs'>¥</span>
              {new Intl.NumberFormat('ja-JP').format(totalAmount)}
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
})
