import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EmailVerification from './components/EmailVerification'
import ForgotPassword from './components/signups/ForgotPassword'
import Home from './components/common/Home'
import Layout from './components/common/Layout'
import ProductsList from './components/products/ProductsList'
import ProductView from './components/products/ProductView'
import Profile from './components/Profile'
import ResetPassword from './components/signups/ResetPassword'
import SignIn from './components/signups/SignIn'
import SignUp from './components/signups/Signup'
import CartView from './components/CartView'
import Checkout from './components/Checkout'
import { DBContext } from './base/DbContext'
import { useCategories, useProducts, useUser } from './base/useDbHooks'
// import { useDbCartHook } from './base/useCartHook'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './firebase'
import { useState } from 'react'
import { DeliveryTimeslots, PickupTimeslots } from './model/Settings'
import OrdersList from './components/Orders'
import Contacts from './components/Contacts'
import Services from './components/Services'
import About from './components/About'
import OrderView from './components/OrderView'

export default function App() {
  const [authUser] = useAuthState(auth)
  const { user } = useUser()
  // const { cartProductSnapshots } = useDbCartHook()
  const { categories } = useCategories()
  const { products } = useProducts()
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    DeliveryTimeslots | PickupTimeslots | undefined
  >(undefined)

  return (
    <>
      <DBContext.Provider
        value={{
          authUser,
          user,
          // cart: cartProductSnapshots,
          categories,
          products,
          selectedTimeslot,
          setSelectedTimeslot,
        }}
      >
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='signin' element={<SignIn />} />
              <Route path='signup' element={<SignUp />} />
              <Route path='emailVerification' element={<EmailVerification />} />
              <Route path='forgotPassword' element={<ForgotPassword />} />
              <Route path='resetPassword' element={<ResetPassword />} />
              <Route path='profile' element={<Profile />} />
              <Route path='product/:id' element={<ProductView />} />
              <Route path='products/:category' element={<ProductsList />} />
              <Route path='cart' element={<CartView />} />
              <Route path='checkout' element={<Checkout />} />
              <Route path='orders' element={<OrdersList />} />
              <Route path='orders/:orderId' element={<OrderView />} />
              <Route path='contacts' element={<Contacts />} />
              <Route path='services' element={<Services />} />
              <Route path='about' element={<About />} />

              {/* <Route path='*' element={<NoPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </DBContext.Provider>
    </>
  )
}
