import React, { forwardRef } from 'react'
interface TitleProps {
  children: React.ReactNode
}
type TitleRef = React.ForwardedRef<HTMLElement>
// eslint-disable-next-line react/display-name
export const Title = forwardRef(({ children, ...props }: TitleProps, ref: TitleRef) => {
  return (
    <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
      {children}
    </h1>
  )
})
