import { useTranslation } from 'react-i18next'
import { IoCartOutline } from 'react-icons/io5'
import { DBContext } from '../base/DbContext'
import { Page } from '../base/Page'
import { Styles } from '../base/Styles'
import { useDeliveryCharges, useDeliveryTimeslots, usePickupTimeslots } from '../base/useDbHooks'
import { useContext, useEffect, useRef, useState } from 'react'
import { CartContent } from './ordering/CartContent'
import { useCartStore } from '../base/CartStore'

export default function Checkout() {
  const { t, i18n } = useTranslation()
  const { items } = useCartStore()
  const [error, setError] = useState<string | undefined | null>(undefined)
  const { selectedTimeslot, setSelectedTimeslot } = useContext(DBContext)

  const cartRef = useRef()
  const confirmBtn = useRef(null) as any

  useEffect(() => {
    // if (selectedTimeslot === undefined) {
    //   setError(t('selectTimeslot'))
    // } else {
    //   setError(undefined)
    // }
  }, [selectedTimeslot])

  const scrollToBottom = () => {
    confirmBtn.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [])

  const checkOut = () => {
    ;(cartRef?.current as any)?.handleCheckout()
  }

  if (!items || items.length === 0) {
    return (
      <>
        <Page>
          <div className='flex flex-col items-center justify-center bg-rb-background w-1/4 rounded-lg shadow-md'>
            <IoCartOutline size='10em' />
            {t('emptyCart')}
          </div>
        </Page>
      </>
    )
  }

  return (
    <>
      <Page>
        <div className='flex flex-col bg-rb-background md:w-4/5 rounded-lg shadow-md p-3 sm:w-full'>
          <div className='flex w-full items-center justify-between py-3'>
            <IoCartOutline size='2em' />
            <button
              className={Styles.button + ' float-right'}
              onClick={checkOut}
              disabled={!!error}
              ref={confirmBtn}
            >
              {t('confirm')}
            </button>
          </div>

          <div className='flex flex-auto flex-wrap'>
            <div>
              <div className='block '>
                <CartContent ref={cartRef} />
              </div>
            </div>
            <div className=' border-2 ml-4 pt-4'>
              <div className='block '>
                <div className='flex flex-col gap-6 justify-start items-start px-4'>
                  <DisplayDeliveryPickup />
                  <DisplayPickupTimeslots />
                  <DisplayAddress />
                </div>
              </div>
            </div>
            {/*
             */}
          </div>

          <div className='flex justify-end items-end pt-10 px-4'>
            {/* <DisplayDeliveryCharges /> */}

            <div>
              <div className='text-red-500'>{error}</div>
              <button
                className={Styles.button + ' float-right'}
                onClick={checkOut}
                disabled={!!error}
                ref={confirmBtn}
              >
                {t('confirm')}
              </button>
            </div>
          </div>
        </div>
      </Page>
    </>
  )

  function DisplayDeliveryPickup() {
    const { deliveryTimeslots } = useDeliveryTimeslots()

    useEffect(() => {
      if (deliveryTimeslots && deliveryTimeslots?.length > 0 && !selectedTimeslot) {
        setSelectedTimeslot(deliveryTimeslots[0])
      }
    }, [deliveryTimeslots])

    return (
      <div className='flex flex-col justify-start items-start'>
        <div className='font-semibold'>{t('deliveryTimeslots')}</div>
        <div className='flex flex-col justify-start items-center'>
          {deliveryTimeslots?.map((d, i) => {
            return (
              <div key={d.name} className='flex justify-start items-center w-40'>
                <input
                  id={'default-radio-1' + d.name}
                  type='radio'
                  value={d.name}
                  name='default-radio'
                  className='w-4 h-4 text-rb-purple bg-white border-rb-purple focus:ring-rb-purple dark:focus:ring-rb-purple dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  checked={selectedTimeslot?.name === d.name}
                  onChange={(e) => {
                    console.log('e.target.value', e.target.value)
                    setSelectedTimeslot(d)
                  }}
                />
                <label
                  htmlFor={'default-radio-1' + d.name}
                  className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  {i18n.language === 'en' ? d.valueEn : d.valueJp}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  function DisplayPickupTimeslots() {
    const { pickupTimeslots } = usePickupTimeslots()
    if (!pickupTimeslots || pickupTimeslots.length === 0) return null
    return (
      <div className='flex flex-col justify-start items-start'>
        <div className='font-semibold'>{t('pickupTimeslots')}</div>
        <div className='flex flex-col justify-start items-center'>
          {pickupTimeslots?.map((d) => {
            return (
              <div key={d.name} className='flex justify-start items-center w-40'>
                <input
                  id={'default-radio-2' + d.name}
                  type='radio'
                  value={d.name}
                  name='default-radio'
                  className='w-4 h-4 text-rb-purple bg-white border-rb-purple focus:ring-rb-purple dark:focus:ring-rb-purple dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  checked={selectedTimeslot?.name === d.name}
                  onChange={(e) => {
                    console.log('e.target.value', e.target.value)
                    setSelectedTimeslot(d)
                  }}
                />
                <label
                  htmlFor={'default-radio-2' + d.name}
                  className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  {i18n.language === 'en' ? d.valueEn : d.valueJp}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  function DisplayAddress() {
    const { user } = useContext(DBContext)
    return (
      <div className='flex flex-col justify-start items-start'>
        <div className='font-semibold'>{t('address')}</div>
        <div>
          {i18n.language === 'en' ? user?.info?.locationAddressEn : user?.info?.locationAddressJp}{' '}
          {user?.info?.address}
        </div>
      </div>
    )
  }
}

export function DisplayDeliveryCharges() {
  const { t, i18n } = useTranslation()
  const { deliveryCharges } = useDeliveryCharges()
  return (
    <div className='flex flex-col justify-start items-start '>
      <div className='font-semibold'>{t('deliveryCharges')}</div>
      <div className='flex flex-col justify-start items-start '>
        {deliveryCharges?.map((d, i) => {
          return (
            <div key={d.range} className='flex justify-start items-center'>
              Total {i === 0 && '¥0 ~ '}
              {i === 1 && '¥' + deliveryCharges[i - 1].range + ' ~ '}¥{d.range} -{' '}
              {t('deliveryCharges')} - ¥{d.deliveryCharge}, {t('deliveryChargesToDoor')} - ¥
              {d.doorDeliveryCharge}
            </div>
          )
        })}
        <div className='italic'>* {t('deliveryConditions')}</div>
      </div>
    </div>
  )
}
