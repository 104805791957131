export const Styles = {
  link: 'font-medium text-primary-600 hover:underline dark:text-primary-500 underline',
  textSm: 'mb-4 text-base font-normal text-gray-500 dark:text-gray-400',

  menu: {
    active:
      ' flex items-center text-sm font-semibold text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white',
    notActive: 'text-gray-900',
    normal: ' w-full py-0 text-sm text-gray-700 dark:text-gray-200',
    box: 'flex gap-2 items-center px-2',
    text: ' flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white',
  },

  button:
    'text-white bg-rb-purple hover:bg-rb-dark focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:opacity-25',
  buttonOutline:
    'py-2.5 px-4 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-rb-purple focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700',
}
