import { Page } from '../../base/Page'
import { Carousel } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { useCategory, useProduct } from '../../base/useDbHooks'
import Loading from '../../base/Loading'
import { Product, ProductPricing } from '../../model/Product'
import { useEffect } from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics, auth } from '../../firebase'
import { useCartHook } from '../../base/useCartHook'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { useAuthState } from 'react-firebase-hooks/auth'
import { format } from 'date-fns'
import { BsWhatsapp, BsLine, BsPhone } from 'react-icons/bs'

export default function ProductView() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const { product } = useProduct(id)
  const { category } = useCategory(product?.category)

  useEffect(() => {
    if (product) {
      logEvent(analytics, 'view_item', {
        items: [
          {
            // eslint-disable-next-line camelcase
            item_id: id,
            // eslint-disable-next-line camelcase
            item_name: product.productNameEn,
            // eslint-disable-next-line camelcase
            item_category: category?.category,
            // eslint-disable-next-line camelcase
            item_list_id: category?.category,
            // eslint-disable-next-line camelcase
            item_list_name: category?.nameEn,
            // price: product.pricing?.['0'].price,
          },
        ],
        productName: product.productNameEn,
      })
    }
  }, [product])

  if (!product) {
    return <Loading />
  }

  return (
    <Page>
      <div className='container sm:px-2 px-5 py-10 mx-auto bg-rb-background '>
        <div className='lg:w-4/5 mx-auto flex flex-wrap '>
          <div className='w-96 md:w-1/2 h-52 md:h-80 sm:h-52 p-4 '>
            <ProductImages product={product} />
          </div>

          <div className='sm:w-full md:w-1/2 p-4'>
            <h2 className='text-sm title-font text-gray-500 tracking-widest'>
              {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
            </h2>
            <h1 className='text-gray-900 text-3xl title-font font-medium mb-1'>
              {i18n.language === 'en' ? product?.productNameEn : product?.productNameJp}
            </h1>
            <div className='flex mb-4'>
              {/* <Ratings /> */}
              <span className='flex ml-3 pl-3 py-2 border-l-2 border-gray-200'>
                <a className='text-gray-500'>
                  <svg
                    fill='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                  </svg>
                </a>
                <a className='ml-2 text-gray-500'>
                  <svg
                    fill='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                  </svg>
                </a>
                <a className='ml-2 text-gray-500'>
                  <svg
                    fill='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                  </svg>
                </a>
              </span>
            </div>
            <span className='leading-relaxed'>
              {i18n.language === 'en'
                ? product?.productDescriptionEn && parse(product?.productDescriptionEn)
                : product?.productDescriptionJp && parse(product?.productDescriptionJp)}
            </span>
            <div className='flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5'></div>
            <Pricing product={product} />
            <OrderByMessage />
            {/* <Favorite /> */}
          </div>
        </div>
      </div>
    </Page>
  )

  function Pricing({ product }: { product?: Product }) {
    const [authUser] = useAuthState(auth)
    const { productPricingSnapshots } = useProductPricing(product?.productId)

    if (!product || !productPricingSnapshots) {
      return <></>
    }

    if (!authUser && !product?.isDirectOrder) {
      return <></>
    }

    return (
      <div className='flex flex-col gap-2'>
        {productPricingSnapshots?.map((s, i) => {
          const pp = s.val() as ProductPricing
          const expireDate = new Date(pp.expireDate ?? '')
          if (pp.expireDate && expireDate.getTime() < new Date().getTime()) {
            return null
          }
          if (pp.stock === 0 || pp.stock === '0') {
            return null
          }
          return <PricingView key={i} product={product} pricing={pp} />
        })}
      </div>
    )
  }

  function PricingView({ product, pricing }: { product: Product; pricing: ProductPricing }) {
    const { addToCart } = useCartHook({ product, productPricing: pricing })

    const expireDate = new Date(pricing.expireDate ?? '')

    return (
      <div className='flex items-baseline gap-2 justify-end'>
        <span className='font-medium text-md text-gray-900'>{pricing.unit}</span>
        <span className='title-font font-medium text-2xl text-gray-900'>
          {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
            pricing.price,
          )}
        </span>
        {!product?.isDirectOrder && (
          <button
            className='flex text-white bg-rb-purple border-0 py-2 px-4 focus:outline-none hover:bg-rb-dark rounded'
            onClick={() => {
              addToCart(product, pricing, 1)
            }}
          >
            {t('add')}
          </button>
        )}

        {pricing.expireDate && (
          <span className='font-extralight text-sm text-gray-900'>
            Exp: {format(expireDate, 'PP')}
          </span>
        )}
      </div>
    )
  }

  function OrderByMessage() {
    if (!product?.isDirectOrder) {
      return <></>
    }

    // WhatsApp & Line link for direct order
    return (
      <div className='flex gap-2 w-full justify-end'>
        <a
          href={`https://wa.me/${
            // process.env.NEXT_PUBLIC_WHATSAPP_NUMBER
            '818035009515'
          }?text=${encodeURIComponent(
            t('orderMessage', {
              productName: i18n.language === 'en' ? product?.productNameEn : product?.productNameJp,
            }) ?? '',
          )}`}
          target='_blank'
          rel='noopener noreferrer'
          className='flex underline underline-offset-4 border-0 py-2 px-4 focus:outline-none hover:bg-rb-purple hover:text-white rounded gap-1'
        >
          <BsWhatsapp size={'2em'} color='25D366' />
          {t('orderByWhatsapp')}
        </a>
        {/* <a
          href={'https://line.me/R/ti/p/%40rbk'}
          target='_blank'
          rel='noopener noreferrer'
          className='flex underline underline-offset-4 border-0 py-2 px-4 focus:outline-none hover:bg-rb-dark hover:text-white rounded gap-1'
        >
          <BsLine size={'2em'} color='00C300' />
          {t('orderByLine')}
        </a> */}
        <a
          href={'tel:0835009515'}
          className='flex underline underline-offset-4 border-0 py-2 px-4 focus:outline-none hover:bg-rb-purple hover:text-white rounded gap-1'
        >
          <BsPhone size={'2em'} color='black' />
          {t('orderByPhone')}
        </a>
      </div>
    )
  }

  function ProductImages({ product }: { product?: Product }) {
    return (
      <Carousel leftControl=' ' rightControl=' '>
        {Object.values(product?.images).map((v: any) => {
          return <img key={v} src={v} alt='...' />
        })}
      </Carousel>
    )
  }

  function Favorite({ product }: { product?: Product }) {
    return (
      <button className='rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4'>
        <svg
          fill='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          className='w-5 h-5'
          viewBox='0 0 24 24'
        >
          <path d='M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z'></path>
        </svg>
      </button>
    )
  }

  function Ratings({ product }: { product?: Product }) {
    return (
      <>
        <div className='flex'>
          <span className='mr-3'>Color</span>
          <button className='border-2 border-gray-300 rounded-full w-6 h-6 focus:outline-none'></button>
          <button className='border-2 border-gray-300 ml-1 bg-gray-700 rounded-full w-6 h-6 focus:outline-none'></button>
          <button className='border-2 border-gray-300 ml-1 bg-red-500 rounded-full w-6 h-6 focus:outline-none'></button>
        </div>
        <div className='flex ml-6 items-center'>
          <span className='mr-3'>Size</span>
          <div className='relative'>
            <select className='rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-500 text-base pl-3 pr-10'>
              <option>SM</option>
              <option>M</option>
              <option>L</option>
              <option>XL</option>
            </select>
            <span className='absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center'>
              <svg
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-4 h-4'
                viewBox='0 0 24 24'
              >
                <path d='M6 9l6 6 6-6'></path>
              </svg>
            </span>
          </div>
        </div>
      </>
    )
  }
}
