import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Page } from '../base/Page'
import { useOrdersList } from '../base/useDbHooks'
import { Order } from '../model/Order'
import { format } from 'date-fns'

export default function OrdersList() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Page>
      <div className='flex flex-col bg-rb-background rounded-lg shadow-md p-3'>
        <div className='flex w-full items-center justify-center py-3 text-2xl'>{t('orders')}</div>
        <DisplayOrders />
      </div>
    </Page>
  )

  function DisplayOrders() {
    const { ordersSnapshot } = useOrdersList()

    return (
      <>
        <div className='flex flex-col'>
          {ordersSnapshot?.map((o, i) => {
            const order = o.val() as Order
            const date = order.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : ''
            return (
              <div
                key={'orders' + i}
                className='flex flex-col justify-between items-center border-b pt-3 pb-6'
              >
                <div className='w-full flex justify-between'>
                  <div>{t('orderId')}:</div> <div>{order.orderId}</div>
                </div>
                <div className='w-full flex justify-between'>
                  {t('orderDate')}: <span className='font-bold'>{date}</span>
                </div>
                <div className='w-full flex justify-between'>
                  {t('orderTotal')}:{' '}
                  <span className='font-bold'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP').format(order.totalAmount)}
                  </span>
                </div>
                <div className='w-full flex justify-between'>
                  {t('orderStatus')}: <span className='font-bold'>{order.orderStatus}</span>
                </div>
                <div className='w-full flex justify-center pt-4'>
                  <button
                    onClick={() => {
                      navigate('/orders/' + order.orderId)
                    }}
                    className='underline'
                  >
                    {t('view')}
                  </button>
                </div>
              </div>
            )
          })}
        </div>

        {/* <table className='table-auto m->3 rounded-lg'>
          <thead className='border-b'>
            <tr>
              <th className='w-52 p-3'>{t('orderId')}</th>
              <th className='w-20 text-center'>{t('orderDate')}</th>
              <th className='w-16 text-center'>{t('orderTotal')}</th>
              <th className='w-24 text-center'>{t('orderStatus')}</th>
              <th className='w-12 text-center'>{t('view')}</th>
            </tr>
          </thead>
          <tbody>
            {ordersSnapshot?.map((o, i) => {
              const order = o.val() as Order
              return (
                <tr key={'orders' + i}>
                  <td className='p-3'>{order.orderId}</td>
                  <td>{}</td>
                  <td className='text-right px-2'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP').format(order.totalAmount)}
                  </td>
                  <td className='text-center'>{order.orderStatus}</td>
                  <td className='text-center'>
                    <button
                      onClick={() => {
                        navigate('/orders/' + order.orderId)
                      }}
                      className='underline'
                    >
                      {t('view')}
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table> */}
      </>
    )
  }
}
