import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DBContext } from '../base/DbContext'
import { Page } from '../base/Page'
import { useOrder, useOrderItems } from '../base/useDbHooks'
import { Order, ProductOrder } from '../model/Order'
import { format } from 'date-fns'

export default function OrderView() {
  const { t, i18n } = useTranslation()
  const { authUser } = useContext(DBContext)
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { orderSnapshot } = useOrder(orderId ?? '')
  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    if (orderSnapshot) {
      const orderVal = orderSnapshot.val() as Order
      if (orderVal && orderVal.orderId === orderId && orderVal.userId === authUser?.uid) {
        setOrder(orderVal)
      }
    }
  }, [orderSnapshot])

  const date = order?.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : ''

  return (
    <Page>
      <div className='flex flex-col bg-rb-background rounded-lg shadow-md p-3'>
        <div className='flex justify-between'>
          <div></div>
          <div className='py-3 text-2xl'>{t('orderView')}</div>
          <div>
            <button
              className='flex underline '
              onClick={() => {
                navigate('/orders')
              }}
            >
              {t('back')}
            </button>
          </div>
        </div>
        {
          // display order and order items
          order && (
            <div className='flex flex-col'>
              <div className='flex flex-col w-full justify-around'>
                <div className='flex w-full'>
                  {t('orderId')}: <span className='font-bold'>{order.orderId}</span>
                </div>
                <div>
                  {t('orderDate')}: <span className='font-bold'>{date}</span>
                </div>
                <div>
                  {t('orderTotal')}:{' '}
                  <span className='font-bold'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                      order.totalAmount,
                    )}
                  </span>
                </div>
                <div>
                  {t('orderStatus')}: <span className='font-bold'>{order.orderStatus}</span>
                </div>
              </div>
              <DisplayOrderProducts />
            </div>
          )
        }
      </div>
    </Page>
  )

  function DisplayOrderProducts() {
    const { orderProductsSnapshot } = useOrderItems(orderId)
    // display order with div and tailwind
    return (
      <div className='flex w-full justify-center pt-4'>
        <div className='flex flex-col w-4/5'>
          {orderProductsSnapshot?.map((o: any, i: number) => {
            const orderProduct = o.val() as ProductOrder
            return (
              <div key={'orderItems' + i} className='flex flex-row justify-between'>
                <div className='flex flex-row'>
                  <div className='flex flex-col justify-center'>
                    <img src={orderProduct.thumbImage} className='rounded w-14' />
                  </div>
                  <div className='flex flex-col justify-center pl-4'>
                    <div className='text-lg'>
                      {i18n.language === 'en' ? orderProduct.nameEn : orderProduct.nameJp}
                    </div>
                    <div className='text-sm'>{orderProduct.quantity}</div>
                  </div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                  <span className='font-thin text-xs'>¥</span>
                  {new Intl.NumberFormat('ja-JP').format(
                    orderProduct.quantity * orderProduct.price,
                  )}
                </div>
              </div>
            )
          })}
        </div>

        {/* <table className='table-auto m->3 rounded-lg'>
          <thead className='border-b'>
            <tr>
              <th className='w-20 p-3'></th>
              <th className='w-52 text-center'>{t('product')}</th>
              <th className='w-20 text-center'>{t('quantity')}</th>
              <th className='w-16 text-right'>{t('price')}</th>
            </tr>
          </thead>
          <tbody>
            {orderProductsSnapshot?.map((o: any, i: number) => {
              const orderProduct = o.val() as ProductOrder
              console.log(orderProduct)
              return (
                <tr key={'orderItems' + i}>
                  <td className='p-3'>
                    <a
                      className='cursor-pointer'
                      href={'/product/' + orderProduct?.productId}
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/product/' + orderProduct?.productId)
                      }}
                    >
                      <img src={orderProduct.thumbImage} className='rounded w-14' />
                    </a>
                  </td>
                  <td className='w-52 text-center'>
                    {' '}
                    {i18n.language === 'en' ? orderProduct.nameEn : orderProduct.nameJp}
                  </td>
                  <td className='w-20 text-center'>{orderProduct.quantity}</td>
                  <td className='w-20 text-right'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP').format(
                      orderProduct.quantity * orderProduct.price,
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table> */}
      </div>
    )
  }
}
