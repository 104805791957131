import { IoCartOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useCartStore } from '../base/CartStore'
import { t } from 'i18next'

export default function Cart() {
  const { items } = useCartStore()
  const navigate = useNavigate()
  return (
    <div className='flex gap-5'>
      <a
        href='/cart'
        className='inline-flex relative items-center p-2 text-sm font-medium text-center rounded-lg '
        onClick={(e) => {
          e.preventDefault()
          navigate('/cart')
        }}
      >
        <IoCartOutline size={'1.6em'} />
        <span className='sr-only'>Cart</span>
        <div className='inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900'>
          {items?.length ?? 0}
        </div>
      </a>
      {/* view cart button */}
      <button
        type='button'
        className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-rb-purple border border-transparent rounded-md shadow-sm hover:bg-rb-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        onClick={(e) => {
          e.preventDefault()
          navigate('/cart')
        }}
      >
        {t('viewCart')}
      </button>
    </div>
  )
}
