import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../base/Page'
import { Product, ProductPricing } from '../../model/Product'
import { useDisplayCategories, useProductsByCategory } from '../../base/useDbHooks'
import { logEvent } from 'firebase/analytics'
import { analytics, auth } from '../../firebase'
import { DataSnapshot } from 'firebase/database'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { useCartHook } from '../../base/useCartHook'
import Cart from '../Cart'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect, useState } from 'react'

export default function ProductsSimple() {
  const { t, i18n } = useTranslation()
  const [user] = useAuthState(auth)

  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()

  logEvent(analytics, 'view_item_list', {
    // eslint-disable-next-line camelcase
    item_list_name: 'products',
  })

  return (
    <>
      <Page>
        <div className='flex flex-col  bg-rb-background-dark w-screen lg:max-w-sm'>
          <div className='flex items-center justify-end px-4 py-2'>{user && <Cart />}</div>
          <div className='pb-32'>
            {displayCategories &&
              displayCategories.map((category) => {
                if (
                  !productsByCategory[category.category] ||
                  productsByCategory[category.category].length === 0
                ) {
                  return null
                }
                return (
                  <div
                    key={category.category}
                    className='flex flex-col gap-1 p-1 cat_container overflow-y-hidden ove  justify-start bg-rb-background-dark my-1 border-4 mb-4'
                  >
                    <div className='text-3xl text-rb-purple  font-semibold text-center'>
                      {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
                    </div>
                    {productsByCategory[category.category]?.map((p: Product) => {
                      return <ProductCardSimple key={p.productId} product={p} />
                    })}
                  </div>
                )
              })}
          </div>
          <div className='fixed z-90 bottom-10 right-8 w-28 h-20 rounded-full drop-shadow-lg flex justify-center items-center hover:animate-bounce duration-300'>
            {user && <Cart />}
          </div>
        </div>
      </Page>
    </>
  )
}

export function ProductCardSimple({ product }: { product: Product }) {
  const [pricings, setPricings] = useState<ProductPricing[]>([])
  const { t, i18n } = useTranslation()
  const { productPricingSnapshots } = useProductPricing(product.productId)

  useEffect(() => {
    if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
      return
    }
    const pricings: ProductPricing[] = []
    productPricingSnapshots.forEach((productPricingSnapshot) => {
      const productPricing = productPricingSnapshot.val() as ProductPricing
      if (productPricing && Number(productPricing.stock) > 0) {
        pricings.push(productPricingSnapshot.val() as ProductPricing)
      }
    })
    setPricings(pricings)
  }, [productPricingSnapshots])

  if (!pricings || pricings.length === 0) {
    return <div className='product_results'></div>
  }
  return (
    <>
      <div className='w-full bg-rb-background rounded-lg shadow-md mb-2 hover:shadow-sm dark:bg-gray-800 dark:border-gray-700'>
        <div className='flex'>
          <div className='w-16'>
            <ProductImage product={product} />
          </div>
          <div className='text-lg font-semibold tracking-tight text-gray-900 dark:text-white'>
            {i18n.language === 'en' ? product.productNameEn : product.productNameJp}
          </div>
        </div>
        <div className='flex w-full justify-end'>
          <Pricing product={product} productPricingSnapshots={productPricingSnapshots} />
        </div>
      </div>
    </>
  )

  function ProductImage({ product }: { product: Product }) {
    let img: string | undefined = undefined
    if (product?.images && Object.values(product?.images)) {
      img = Object.values(product?.images)[0] as string
    }
    return (
      <>
        <div className='p-2  aspect-square'>
          {img && <img className='rounded aspect-square ' src={img} alt='...' />}
        </div>
      </>
    )
  }
}

function Pricing({
  product,
  productPricingSnapshots,
}: {
  product: Product
  productPricingSnapshots: DataSnapshot[]
}) {
  if (!product || productPricingSnapshots.length === 0) {
    return <></>
  }

  return (
    <>
      <table className='w-full'>
        <tbody>
          {productPricingSnapshots?.map((productPricingSnapshot: DataSnapshot, index) => {
            const pricing = productPricingSnapshot.val() as ProductPricing

            if (!pricing || Number(pricing.stock) <= 0) {
              return <></>
            }

            return (
              <ProductPricingCard key={pricing.pricingId} product={product} pricing={pricing} />
            )
          })}
        </tbody>
      </table>
    </>
  )
}

function ProductPricingCard({ product, pricing }: { product: Product; pricing: ProductPricing }) {
  const { addToCart, setQuantity, cartProduct } = useCartHook({ product, productPricing: pricing })

  return (
    <tr className='border-4'>
      <td>
        {pricing.unit}
        {pricing.expireDate && (
          <span className='text-xs'>
            <br></br>Exp: {pricing.expireDate?.toString()}
          </span>
        )}
      </td>
      <td>{pricing.price}円</td>
      <td></td>
      {/* add to cart quantity with plus and minus buttons */}
      <td className='float-right'>
        <div>
          <label htmlFor='Quantity' className='sr-only'>
            Quantity
          </label>

          <div className='flex items-center gap-1'>
            <button
              type='button'
              onClick={() => {
                if (cartProduct) {
                  setQuantity(cartProduct, cartProduct.quantity - 1)
                }
              }}
              className='w-8 h-8 bg-red-300 text-white rounded-full  leading-10 transition hover:opacity-75'
            >
              &minus;
            </button>

            <input
              type='number'
              id='Quantity'
              value={cartProduct?.quantity ?? 0}
              onChange={(e) => addToCart(product, pricing, parseInt(e.target.value))}
              className='h-10 w-6 m-0 p-1 rounded justify-center border-gray-200 [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none'
            />

            <button
              type='button'
              onClick={() => {
                if (cartProduct) {
                  setQuantity(cartProduct, cartProduct.quantity + 1)
                } else {
                  addToCart(product, pricing, 1)
                }
              }}
              className='w-8 h-8 bg-green-600 text-white rounded-full  leading-10 transition hover:opacity-75'
            >
              +
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}
