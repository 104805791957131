import { useForm } from 'react-hook-form'
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSignInWithGoogle,
} from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { IoAlertOutline, IoLogoGoogle } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import * as Yup from 'yup'
import { InputText } from '../../base/InputText'
import { Card } from '../../base/Card'
import { Page } from '../../base/Page'
import { Title } from '../../base/Title'
import { Styles } from '../../base/Styles'
import { useYupValidationResolver } from '../../base/validateResolver'
import { useEffect } from 'react'
import { Alert, Button } from 'flowbite-react'
import { FcGoogle } from 'react-icons/fc'

const formSchema = Yup.object().shape({
  email: Yup.string().required('error.required').email('error.invalidEmail'),
  password: Yup.string()
    .required('error.required')
    .min(6, 'error.passwordshouldbeatleast6characters')
    .max(12, 'error.PasswordcannotexceedMoreThan12characters'),
  confirmPassword: Yup.string()
    .required('error.required')
    .min(6, 'error.passwordshouldbeatleast6characters')
    .max(12, 'error.PasswordcannotexceedMoreThan12characters')
    .oneOf([Yup.ref('password')], 'error.Passwordsdonotmatch'),
})

export default function SignUp() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [signInWithGoogle] = useSignInWithGoogle(auth)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(formSchema) })

  const [createUserWithEmailAndPassword, newUser, signUpLoading, signUpError] =
    useCreateUserWithEmailAndPassword(auth, {
      sendEmailVerification: true,
      emailVerificationOptions: {
        url: 'http://localhost:3000',
      },
    })
  const [user] = useAuthState(auth)

  console.log(getValues(), errors, signUpError?.code)

  const onSubmit = (value: any) => {
    console.log(value)
    createUserWithEmailAndPassword(value.email, value.password)
      .then((res) => {
        console.log('--res', res)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <>
      <Page>
        <Card className='bg-rb-background'>
          <div className='p-2 space-y-2 md:space-y-2 sm:p-8'>
            <Title>{t('signUp')}</Title>
            {signUpError?.code && (
              <Alert color='failure' icon={IoAlertOutline}>
                {t(signUpError?.code)}
              </Alert>
            )}
            <form className='flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputText
                  {...register('email')}
                  name={'email'}
                  label={t('yourEmail') ?? 'Email'}
                  placeholder='name@royalbakes.jp'
                  error={t(errors?.email?.message as string) ?? undefined}
                />
              </div>
              <div>
                <InputText
                  {...register('password')}
                  name={'password'}
                  type='password'
                  label={t('yourPassword') ?? 'Password'}
                  error={t(errors?.password?.message as string) ?? undefined}
                />
              </div>
              <div>
                <InputText
                  {...register('confirmPassword')}
                  name={'confirmPassword'}
                  type='password'
                  label={t('confirmPassword') ?? 'Confirm Password'}
                  error={t(errors?.confirmPassword?.message as string) ?? undefined}
                />
              </div>
              <button
                className='bg-rb-purple text-white py-2 px-4 rounded-lg font-bold hover:bg-rb-purple-dark'
                type='submit'
              >
                {t('signUp')}
              </button>
              <div className='flex w-full items-center justify-center'>Or</div>
              <div className='flex w-full items-center justify-center'>
                <button
                  className='flex items-center gap-2 w-full justify-center border bg-white border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md'
                  onClick={() => signInWithGoogle()}
                >
                  <FcGoogle size='2em' />
                  {t('signUpGoogle')}
                </button>
              </div>
              <span className={Styles.textSm}>
                {t('doYouHaveAnAccount')}{' '}
                <Link to='/signin' className={Styles.link}>
                  {t('signIn')}
                </Link>
              </span>
            </form>
          </div>
        </Card>
      </Page>
    </>
  )
}
