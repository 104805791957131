import { Dropdown } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import { IoLanguage } from 'react-icons/io5'

export default function Language() {
  const { t, i18n } = useTranslation()

  return (
    <div className='flex items-center px-2'>
      <Dropdown
        label={
          <>
            <IoLanguage size={'1.2em'} />
          </>
        }
        color='light'
        size='sm'
        inline={true}
        arrowIcon={false}
      >
        <Dropdown.Item
          onClick={() => {
            i18n.changeLanguage('ja')
          }}
        >
          日本語
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            i18n.changeLanguage('en')
          }}
        >
          English
        </Dropdown.Item>
      </Dropdown>
    </div>
  )
}
